/* Extra small devices (phones, less than 768px) */
@media (max-width: 767.98px) {
    .logoDis {
        display: block;
        flex: 1;
        text-align: left;
    }
    .toggleOrg {
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        width: 100%;
        height: 100%;
    }
    .toggleButton {
        flex: 1;
        text-align: center;
    }
    .userDropdown {
        flex: 1;
        text-align: right;
    }
}

/* Medium devices and above (768px and up) */
@media (min-width: 768px) {
    .logoDis {
        display: block;
        margin-left: 6px;
        border-left: 1px solid var(--gray-700);
        padding-left: 4px;
    }
    .toggleOrg {
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }
}

@media (min-width: 992px) {
    .logoDis {
        margin-left: 6px;
        border-left: 2px solid var(--gray-700);
        padding-left: 4px;
    }
    .toggleOrg {
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }
}

@media (min-width: 1200px) {
    .logoDis {
        margin-left: 6px;
        border-left: 2px solid var(--gray-700);
        padding-left: 4px;
    }
}
 