@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    margin: 0px;
    padding: 0px;
    overflow: hidden;
}

