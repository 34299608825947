/* .chat-input-container {
    padding-bottom: env(safe-area-inset-bottom);
  } */

/* @media only screen and (device-width: 32px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .chat-input-container {
    padding-bottom: 80px;
  }
} */

/* target Android devices with a navigation bar */
/* @media only screen and (max-width: 768px) and (orientation: portrait) {
  .chat-input-container {
    padding-bottom: 5px;
  }
} */


/* border css */
.custom-border-light {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%239FABCA' stroke-width='2' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
}

.dark .custom-border-dark {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%239fabca70' stroke-width='2' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari */
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* Hides scrollbar in IE/Edge */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}

::-webkit-scrollbar {
  display: none;
}