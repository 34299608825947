.toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  height: 6rem;
  background-color: #e2e2e2;
  border-radius: 9999px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
}

.toggle.active {
  background-color: #4caf50;
}

.toggle .circle {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 5rem;
  height: 5rem;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle.active .circle {
  transform: translateX(6rem);
}

/* can add in the sidebar  */

.hide-scrollbar {
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.dropdown-transition {
  transition: all 0.2s ease-in-out;
}

.user-avatar {
  background: linear-gradient(45deg, #4fd1c5, #3182ce);
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Hides scrollbar but still allows scrolling */
.side-chat-scroll {
  overflow: auto !important; /* Ensures scrolling is still possible */
  scrollbar-width: none !important; /* For Firefox */
  -ms-overflow-style: none !important; /* For Internet Explorer and Edge */
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

.side-chat-scroll::-webkit-scrollbar {
  display: none !important; /* For Chrome, Safari, and Edge */
}
.side-chat-scroll .nav-item {
  scroll-snap-align: end;
}


/* Extra small devices (phones, less than 768px) */
@media (max-width: 767.98px) {
 .sidebar{
  position: fixed;
  top: 0;
  left: 0;
 }
}

/* Medium devices and above (768px and up) */
@media (max-width: 1024px) {
  .sidebar{
    position: fixed;
    top: 0;
    left: 0;
   }
}